export function sortLanguages(data) {
  let languages = data?.items?.reduce(
    (acc, item) => ((acc[item.language] = (acc[item.language] || 0) + 1), acc),
    {}
  );
  let sortedLanguages = [];
  for (let lang in languages) {
    if (lang !== "null") {
      sortedLanguages.push({ label: lang, count: languages[lang] });
    }
  }

  sortedLanguages
    .sort((a, b) => {
      return a.count - b.count;
    })
    .reverse();

  return sortedLanguages;
}

export function formatTime(minutes, seconds) {
  return parseInt(minutes) > 0 ? `${minutes} mins ${seconds} secs` : `${seconds} secs`;
};


export function getTotalTime(assessment1)  {
  if(assessment1.started_time && assessment1.grammer_time && assessment1.recording_time && assessment1.listening_time) {
    const startDate = new Date(0);
    startDate.setUTCSeconds(assessment1.started_time.seconds);

    const endDate = new Date(0);
    endDate.setUTCSeconds(assessment1.listening_time.seconds);

    // const overallTime = Math.abs(endDate - startDate);
    const overallTimeInSeconds = Math.abs(endDate - startDate) / 1000;
    const overallMinutes = Math.floor(overallTimeInSeconds / 60);
    const overallSeconds = Math.floor(overallTimeInSeconds % 60);
    
    const overallTime = `${overallMinutes.toString().padStart(2, "0")}:${overallSeconds.toString().padStart(2, "0")}`;

    return overallTime

    // const grammerDate = new Date(0);
    // grammerDate.setUTCSeconds(assessment1.grammer_time.seconds);

    // const recordingDate = new Date(0);
    // recordingDate.setUTCSeconds(assessment1.recording_time.seconds);


    // const overAllMinutes = Math.floor(overallTime / 1000 / 60);
    // const overAllSeconds = Math.floor((overallTime / 1000) % 60);


    // const grammerDeltaTime = Math.abs(grammerDate - startDate);

    // const grammerMinutes = Math.floor(grammerDeltaTime / 1000 / 60);
    // const grammerSeconds = Math.floor((grammerDeltaTime / 1000) % 60);

    // const recordingDeltaTime = Math.abs(recordingDate - grammerDate);

    // const recordingMinutes = Math.floor(recordingDeltaTime / 1000 / 60);
    // const recordingSeconds = Math.floor((recordingDeltaTime / 1000) % 60);

    // const listeningDeltaTime = Math.abs(endDate - recordingDate);

    // const listeningMinutes = Math.floor(listeningDeltaTime / 1000 / 60);
    // const listeningSeconds = Math.floor((listeningDeltaTime / 1000) % 60);

    // setStartTime(formatTime(overAllMinutes, overAllSeconds))
    // setGrammerTime(formatTime(grammerMinutes, grammerSeconds))
    // setRecordingTime(formatTime(recordingMinutes, recordingSeconds))
    // setListeningTime(formatTime(listeningMinutes, listeningSeconds))
  }
  
}
