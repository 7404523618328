import React, { useState } from 'react';
import {app, db, usersCol, auth, admin, secondAuth } from '../firebase';
import { collection, addDoc, setDoc, doc, query, where, limit, getDocs } from 'firebase/firestore/lite';
import Papa from 'papaparse';
import SoftBox from './SoftBox';
import { FormControl, InputLabel, MenuItem, Select, useMediaQuery } from '@mui/material';

const QuestionsUploader = () => {
  const [questionsFile, setQuestionsFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("");

  const handleFileChange = (event) => {
    setQuestionsFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!questionsFile) {
      setUploadError('Please select a file to upload.');
      return;
    }

    Papa.parse(questionsFile, {
      header: true,
      complete: async (results) => {
        const questions = results.data.map((row) => {
          const options = [
            row.option1,
            row.option2,
            row.option3,
            row.option4,
          ];
          const obj = {
            question: row.question,
            options: options,
            correct_answer: row.correctAnswer,
            difficulty: row.difficulty,
            category: row.category,
            region: selectedRegion
          };

          const q2 = query(
            collection(db, "questions"),
            where("question", "==", row.question),
            limit(1) // Add limit function here
          );

          getDocs(q2).then((docSnapshot) => {
            if (docSnapshot.exists) {
              const questionsData = docSnapshot.docs[0].data();
              console.log(questionsData);
            } else {
              console.log("No data found for the questionsData.");

              const questionRef = collection(db, "questions");
              addDoc(questionRef, obj).then((newQuestionRef) => {
                console.log("Document written with ID: ", newQuestionRef.id);
              }).catch((error) => {
                console.error(error);
              });
            }
          }).catch((error) => {
            console.error(error);
          });


          return obj;
        });
      },
      error: (error) => {
        console.error(error);
        setUploadError('An error occurred while parsing the CSV file.');
      },
    });
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleRegionChange = (event) => {
    const value = event.target.value;
    setSelectedRegion(value);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      <SoftBox
        display="flex"
        flexDirection="row"
        style={{ paddingRight: "16px" }}
      >
        <FormControl
          size="small"
          // sx={{
          //   marginBottom: isMobile ? "8px" : "0",
          //   width: isMobile ? "100%" : "250px",
          // }}
        >
          <InputLabel style={{ fontSize: "1rem", color: "inherit" }}>
            Region
          </InputLabel>

          <Select
            onChange={(event) => handleRegionChange(event)}
            style={{
              fontSize: "1rem",
              color: "inherit",
              paddingRight: "1px !important",
            }}
            sx={{
              "& .MuiSelect-select": {
                minWidth: isMobile ? "100%" : "200px",
              },
            }}
          >
            <MenuItem value="IND">India</MenuItem>
            <MenuItem value="DR">Dominican Republic</MenuItem>
            <MenuItem value="PHIL">Phillippines</MenuItem>
          </Select>
        </FormControl>
      </SoftBox>
      
      <button onClick={handleUpload}>Upload</button>

      {uploadError && <div>{uploadError}</div>}
    </div>
  );
};

export default QuestionsUploader;
