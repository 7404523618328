import React, { useState } from "react";
import { TextField,Button, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore/lite";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  
} from "@mui/material";
import SoftBox from "./SoftBox";

const Heading = styled(Typography)({
  marginBottom: "1rem",
});

const TextArea = styled(TextField)({
  width: "100%",
  marginBottom: "1rem",
});

const ReadingPassageUploader = () => {
  const [passage1, setPassage1] = useState("");
  const [passage2, setPassage2] = useState("");
  const [passage3, setPassage3] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");

  const [title, setTitle] = useState("");
  const [uploadError, setUploadError] = useState(null);

  const handleTextAreaChange = (event, identifier) => {
    const value = event.target.value;
    switch (identifier) {
      case "passage1":
        setPassage1(value);
        break;
      case "passage2":
        setPassage2(value);
        break;
      case "passage3":
        setPassage3(value);
        break;
      default:
        break;
    }
  };

  const handleUpload = async () => {
    if (passage1) {
      const passageRef = collection(db, "reading_passages");
      addDoc(passageRef, { passage1, passage2, passage3, title, region: selectedRegion })
        .then((newPassageRef) => {
          console.log("Document written with ID: ", newPassageRef.id);
          setPassage1("");
          setPassage2("");
          setPassage3("");
          setTitle("");
        })
        .catch((error) => {
          console.error(error);
          setUploadError("Failed to upload passage");
        });
    }
  };
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleRegionChange = (event) => {
    const value = event.target.value;
    setSelectedRegion(value);
  };
  return (
    <div>
      <Heading variant="h5">Upload Reading Passage hello</Heading>
      <SoftBox
        display="flex"
        flexDirection="row"
        style={{ paddingRight: "16px" }}
      >
        <TextArea
          id="passage_111"
          label="Passage 1"
          variant="outlined"
          multiline
          rows={10}
          value={passage1}
          // onChange={handleTextAreaChange}
          onChange={(event) => handleTextAreaChange(event, "passage1")}
          style={{ minWidth: "90% !important", width: "100% !important" }}
        />
      </SoftBox>

      <TextArea
        label="Passage 2"
        variant="outlined"
        multiline
        rows={10}
        value={passage2}
        // onChange={handleTextAreaChange}
        onChange={(event) => handleTextAreaChange(event, "passage2")}
        style={{ minWidth: "90%", width: "100%" }}

      />
      <TextArea
        label="Passage 3"
        variant="outlined"
        multiline
        rows={10}
        value={passage3}
        // onChange={handleTextAreaChange}
        onChange={(event) => handleTextAreaChange(event, "passage3")}
        style={{ minWidth: "90%", width: "100%" }}

      />
      <SoftBox
        display="flex"
        flexDirection="row"
        style={{ paddingRight: "16px" }}
      >
        <FormControl
          size="small"
          // sx={{
          //   marginBottom: isMobile ? "8px" : "0",
          //   width: isMobile ? "100%" : "250px",
          // }}
        >
          <InputLabel style={{ fontSize: "1rem", color: "inherit" }}>
            Region
          </InputLabel>

          <Select
            onChange={(event) => handleRegionChange(event)}
            style={{
              fontSize: "1rem",
              color: "inherit",
              paddingRight: "1px !important",
            }}
            sx={{
              "& .MuiSelect-select": {
                minWidth: isMobile ? "100%" : "200px",
              },
            }}
          >
            <MenuItem value="IND">India</MenuItem>
            <MenuItem value="DR">Dominican Republic</MenuItem>
            <MenuItem value="PHIL">Phillippines</MenuItem>
          </Select>
        </FormControl>
      </SoftBox>

      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload
      </Button>
      {uploadError && <div>{uploadError}</div>}
    </div>
  );
};

export default ReadingPassageUploader;
