import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { db } from "../firebase";
import Box from "@mui/material/Box";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore/lite";

import {
  Card,
  Button,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { AudioWrapper, BEGIN_TEST } from "./helper";
import Banner from "./Banner";
import MySpinner from "./MySpinner";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import SoftButton from "./SoftButton";

const VocabCard = ({ vocab, key }) => {
  return (
    <SoftBox mb={1}>
      <Card variant="outlined" key={key} mb={2}>
        <SoftBox lineHeight={0}>
          <SoftBox mb={1} pl={1} bgColor="grey-100">
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              color="dark"
              style={{ paddingBottom: "8px" }}
            >
              {vocab.question}
            </SoftTypography>
          </SoftBox>
          <SoftBox mt={1} pl={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="text">
              Answered: &nbsp;&nbsp;&nbsp; {vocab.answer}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Card>
    </SoftBox>
  );
};

export default function Review() {
  const [assessment, setAssessment] = useState();

  const [vocabText, setVocabText] = useState([]);
  // const [assessments, setAssessments] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [audioUrl, setAudioUrl] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [listeningTestArray, setListeningTestArray] = useState([]);
  const [testStatus, setTestStatus] = useState(null);
  const [success, setSuccess] = useState(false);

  let { q } = useParams();

  const REVIEW_RECORDING = "Review Recording";
  const REVIEW_QUESTIONS = "Review Questions";
  const REVIEW_LISTENING = "Review Listening";
  const steps = [REVIEW_RECORDING, REVIEW_QUESTIONS, REVIEW_LISTENING];

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    setIsLoading(true);
    const userRef = doc(db, "users", q);
    const assessmentRef = doc(db, "assessments", q);

    getDoc(userRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          userData.id = docSnapshot.id;

          const q1 = query(
            collection(db, "assessments"),
            where("userId", "==", docSnapshot.id),
            orderBy("createdAt", "desc"),
            limit(1)
          );
          const querySnapshot = await getDocs(q1);
          if (!querySnapshot.empty) {
            const assessment = querySnapshot.docs[0].data();
            assessment.uid = querySnapshot.docs[0].id;
            setAssessment(assessment);

            setAudioUrl(assessment.audioUrl);
            setIsLoading(false);
            setVocabText(assessment.vocabtext || []);
            if (assessment.currentStep) {
              setTestStatus(assessment.currentStep);
            }
            if (assessment.listeningAnswer) {
              const groupedAnswers = assessment.listeningAnswer.reduce(
                (acc, curr) => {
                  if (!acc[curr.audioFile]) {
                    acc[curr.audioFile] = [];
                  }
                  acc[curr.audioFile].push(curr);
                  return acc;
                },
                {}
              );
              const groupedAnswersArray = Object.entries(groupedAnswers).map(
                ([audioFile, answers]) => ({
                  audioFile,
                  answers,
                })
              );

              setListeningTestArray(groupedAnswersArray);
            }

            // const assessmentArray = querySnapshot.docs.map((doc) => {
            //   const assessment = doc.data();
            //   assessment.uid = doc.id;
            //   return assessment;
            // });
            // setAssessments(assessmentArray);
          }

          console.log(userData);
          setUser(userData);
        } else {
          console.log("Document not found!");
          // redirect back to previous page here
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  }, [q]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const renderStep2 = () => {
    return (
      // <Box sx={{ width: "100%", overflowX: "auto" }}>
      <div>
        <div>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              maxWidth: "100%",
              display: "flex",
              my: 4,
              // "& .MuiStepLabel-label": { fontSize: "2rem" },
              "& .MuiStepIcon-root": { width: "0.8em", height: "0.8em" },
              connector: {
                lineHeight: 1.6,
                "@media (min-width: 600px)": {
                  lineHeight: 1,
                },
              },
            }}
            // sx={{ "& .MuiStep-root": { paddingLeft: 0, paddingRight: 0 } }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  {/* <StepLabel sx={{ fontSize: "12px", maxWidth: "100px" }}>{label}</StepLabel> */}
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": {
                        lineHeight: isMobile ? 1.4 : 1.6,
                        fontSize: isMobile ? 14 : 16,
                        height: "20px",
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </>
          ) : (
            <>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}

              {renderStep()}
              {assessment
                ? console.log(
                    "What is activeStep and currentSteps",
                    activeStep,
                    assessment.currentStep
                  )
                : null}

              {testStatus && assessment.currentStep !== BEGIN_TEST && (
                <Container
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    width: "auto",
                    // opacity: 0.8,
                    color: "white",
                    height: "3rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.25)",
                    position: "fixed",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 999,
                    // margin: "0 1rem 1rem",
                    // marginBottom: "1rem",
                  }}
                >
                   {activeStep === 0 ? null : (
                  <SoftButton
                    color="gt_primary"
                    variant="text"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </SoftButton>)}

                  <Box sx={{ flexGrow: 1 }} />
                  {activeStep === steps.length - 1 ? null : (
                    <SoftButton
                      color="gt_primary"
                      variant="text"
                      onClick={handleNext}
                    >
                      Next
                    </SoftButton>
                  )}
                </Container>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderStep = () => {
    if (!assessment) return <></>;

    switch (steps[activeStep]) {
      case REVIEW_RECORDING:
        return (
          <Container>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={0}>
                <SoftTypography variant="body2" fontWeight="bold">
                  Recording
                </SoftTypography>
              </SoftBox>
              <Card
                variant="outlined"
                // style={{ marginLeft: "2px", marginRight: "2px" }}
              >
                {audioUrl ? (
                  <SoftBox pt={3} px={2}>
                    <SoftTypography
                      variant="h5"
                      fontWeight="medium"
                      mt={1}
                      mb={2}
                    >
                      Your recording transcribe
                    </SoftTypography>
                    <AudioWrapper
                      style={{ marginTop: "12px", marginBottom: "12px" }}
                    >
                      <audio controls src={audioUrl}></audio>
                    </AudioWrapper>
                  </SoftBox>
                ) : null}
                <SoftBox pt={3} px={2} mb={3}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    {assessment.transcribe}
                  </SoftTypography>
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox pb={5} />
          </Container>
        );
      case REVIEW_QUESTIONS:
        return (
          <Container>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={0}>
                <SoftTypography
                  variant="body2"
                  fontWeight="bold"
                  color="dark"
                >
                  Vocabulary and Grammer
                </SoftTypography>
              </SoftBox>
              <>
                <SoftBox pt={1} px={0}>
                  {vocabText.length > 0 ? (
                    vocabText.map((vocab, index) => (
                      <VocabCard key={index} vocab={vocab} />
                    ))
                  ) : (
                    <SoftTypography
                      variant="h6"
                      fontWeight="medium"
                      color="dark"
                    >
                      No Vocababulary answers found
                    </SoftTypography>
                  )}
                </SoftBox>
              </>
            </SoftBox>
            <SoftBox pb={5} />
          </Container>
        );
      case REVIEW_LISTENING:
        return (
          <Container>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={0}>
                <SoftTypography variant="body2" fontWeight="bold">
                  Listening
                </SoftTypography>
              </SoftBox>

              {listeningTestArray.map((audioObj, index) => (
                <SoftBox pt={1} px={0}>
                  <SoftBox pb={1} px={1}>
                    <AudioWrapper
                      controls
                      style={{ width: "100%", margin: "1rem 0" }}
                    >
                      <audio controls>
                        <source src={audioObj.audioFile} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </AudioWrapper>
                  </SoftBox>
                  <SoftBox pt={3} px={1}>
                    {audioObj.answers.map((answer, index) => (
                      <VocabCard vocab={answer} key={index} />
                    ))}
                  </SoftBox>
                </SoftBox>
              ))}

              {/* add your components here */}
            </SoftBox>
            <SoftBox pb={5} />
          </Container>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return <MySpinner />;
  }

  return (
    <div>
      <Banner showBackButton={true} />
      {success && (
        <div style={{ textAlign: "center", margin: "10px" }}>Email Sent</div>
      )}
      {renderStep2()}
    </div>
  );
}
