import React, { useState, useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore/lite";
import https from "https";

import { Card, Button, Container, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import {
  AudioWrapper,
  BEGIN_TEST,
  StyledLink,
  transcribeAudio,
} from "./helper";
import Banner from "./Banner";
import { diff_match_patch } from "diff-match-patch";
import ErrorIcon from "@material-ui/icons/Error";
import MySpinner from "./MySpinner";
import { Tooltip } from "./StyledComps";
import SoftTypography from "./SoftTypography";
import SoftBox from "./SoftBox";
import SoftInput from "./SoftInput";
import SoftButton from "./SoftButton";
import { PDFViewer, Document, Page, Text, pdf } from "@react-pdf/renderer";
import ReactDOMServer from "react-dom/server";
import { auth } from "../firebase";
import TagManager from "react-gtm-module";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import step from "../assets/theme/components/stepper/step";

const UserTranscribe = styled.p`
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const DiffWrapper = styled.span`
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

const DiffAdd = styled.span`
  background-color: #c8f7c5;
  &:hover {
    background-color: #8be38c;
  }
`;

const DiffDelete = styled.span`
  background-color: #ffb6ba;
  &:hover {
    background-color: #ff7f7f;
  }
`;

const VocabCard = ({ vocab, lastItem }) => {
  return (
    <Card mb={2} variant="outlined" style={{ marginBottom: "1rem" }}>
      <SoftBox
        position="relative"
        // sx={(theme) => timelineItem(theme, { lastItem })}
      >
        <SoftBox lineHeight={0}>
          <SoftBox mb={1} pl={1} bgColor="grey-100">
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              color="dark"
              style={{ paddingBottom: "8px" }}
            >
              {vocab?.question}
            </SoftTypography>
          </SoftBox>
          <SoftBox px={2}>
            <SoftBox mt={2}>
              <SoftTypography variant="h6" fontWeight="medium" color="text">
                Answered: &nbsp;&nbsp;&nbsp; {vocab.answer}
              </SoftTypography>
            </SoftBox>
            <SoftBox mt={2} mb={1.5}>
              <SoftTypography
                variant="h6"
                fontWeight="medium"
                color="text"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                }}
              >
                Correct Answer: &nbsp;&nbsp;&nbsp;
                {vocab.answer?.toLowerCase() ===
                vocab.correct_answer?.toLowerCase() ? (
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    color="text"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      color: "green",
                    }}
                  >
                    {vocab.correct_answer}
                  </SoftTypography>
                ) : (
                  <SoftTypography
                    variant="h6"
                    fontWeight="medium"
                    color="text"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // justifyContent: "space-between",
                      color: "red",
                    }}
                  >
                    {vocab.correct_answer}
                    <ErrorIcon
                      fontSize="small"
                      // className="tooltip"
                      paddingLeft="10px"
                      paddingBottom="5px"
                      style={{ marginLeft: "5px", paddingBottom: "3px" }}
                      // title="Incorrect Answer"
                    />
                  </SoftTypography>
                )}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
};

export default function Details() {
  const [startTime, setStartTime] = useState("");
  const [grammerTime, setGrammerTime] = useState("");
  const [recordingTime, setRecordingTime] = useState("");
  const [listeningTime, setListeningTime] = useState("");

  const [pdfFile, setPdfFile] = useState(null);
  const [isSinglePage, setIsSinglePage] = useState(false);
  const [assessment, setAssessment] = useState();
  const [vocabText, setVocabText] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState();
  const [audioUrl, setAudioUrl] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [listeningTestArray, setListeningTestArray] = useState([]);
  const [testStatus, setTestStatus] = useState(null);
  const [success, setSuccess] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackPass, setFeedbackPass] = useState(false);

  let { q } = useParams();

  const SCREENING = "Screening";
  const REVIEW_RECORDING = "Review Recording";
  const REVIEW_QUESTIONS = "Review Questions";
  const REVIEW_LISTENING = "Review Listening";
  const FEEDBACK = "Feedback";
  const steps = [
    SCREENING,
    REVIEW_RECORDING,
    REVIEW_QUESTIONS,
    REVIEW_LISTENING,
    FEEDBACK,
  ];

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    setIsLoading(true);
    const userRef = doc(db, "users", q);

    getDoc(userRef)
      .then(async (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          userData.id = docSnapshot.id;

          const q1 = query(
            collection(db, "assessments"),
            where("userId", "==", docSnapshot.id),
            orderBy("createdAt", "desc"),
            limit(20)
          );
          const querySnapshot = await getDocs(q1);
          if (!querySnapshot.empty) {
            const assessment = querySnapshot.docs[0].data();
            assessment.uid = querySnapshot.docs[0].id;
            setAssessment(assessment);
            setTestTimes(assessment)
            
            setFeedback(assessment.feedback);
            setAudioUrl(assessment.audioUrl);
            setIsLoading(false);
            if (!assessment.transcribe && assessment.audioUrl) {
              transcribeAudio(assessment);
            }

            setVocabText(assessment.vocabtext || []);
            if (assessment && assessment.currentStep) {
              setTestStatus(assessment.currentStep);
            }
            if (assessment.listeningAnswer) {
              const groupedAnswers = assessment.listeningAnswer.reduce(
                (acc, curr) => {
                  if (!acc[curr.audioFile]) {
                    acc[curr.audioFile] = [];
                  }
                  acc[curr.audioFile].push(curr);
                  return acc;
                },
                {}
              );
              const groupedAnswersArray = Object.entries(groupedAnswers).map(
                ([audioFile, answers]) => ({
                  audioFile,
                  answers,
                })
              );

              setListeningTestArray(groupedAnswersArray);
            }

            const assessmentArray = querySnapshot.docs.map((doc) => {
              const assessment = doc.data();
              assessment.uid = doc.id;
              return assessment;
            });
            setAssessments(assessmentArray);
          }
          setUser(userData);
        } else {
          console.log("Document not found!");
          // redirect back to previous page here
        }
    })
      .catch((error) => {
        console.error("Error getting document: ", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [q]);

  const formatTime = (minutes, seconds) => {
    return parseInt(minutes) > 0 ? `${minutes} mins ${seconds} secs` : `${seconds} secs`;
  };

  const setTestTimes = (assessment1) => {
    if(assessment1.started_time && assessment1.grammer_time && assessment1.recording_time && assessment1.listening_time) {
      const startDate = new Date(0);
      startDate.setUTCSeconds(assessment1.started_time.seconds);
  
      const grammerDate = new Date(0);
      grammerDate.setUTCSeconds(assessment1.grammer_time.seconds);
  
      const recordingDate = new Date(0);
      recordingDate.setUTCSeconds(assessment1.recording_time.seconds);
  
      const endDate = new Date(0);
      endDate.setUTCSeconds(assessment1.listening_time.seconds);
  
      const overallTime = Math.abs(endDate - startDate);
  
      const overAllMinutes = Math.floor(overallTime / 1000 / 60);
      const overAllSeconds = Math.floor((overallTime / 1000) % 60);
  
  
      const grammerDeltaTime = Math.abs(grammerDate - startDate);
  
      const grammerMinutes = Math.floor(grammerDeltaTime / 1000 / 60);
      const grammerSeconds = Math.floor((grammerDeltaTime / 1000) % 60);
  
      const recordingDeltaTime = Math.abs(recordingDate - grammerDate);
  
      const recordingMinutes = Math.floor(recordingDeltaTime / 1000 / 60);
      const recordingSeconds = Math.floor((recordingDeltaTime / 1000) % 60);
  
  
      const listeningDeltaTime = Math.abs(endDate - recordingDate);
  
      const listeningMinutes = Math.floor(listeningDeltaTime / 1000 / 60);
      const listeningSeconds = Math.floor((listeningDeltaTime / 1000) % 60);
  
      setStartTime(formatTime(overAllMinutes, overAllSeconds))
      setGrammerTime(formatTime(grammerMinutes, grammerSeconds))
      setRecordingTime(formatTime(recordingMinutes, recordingSeconds))
      setListeningTime(formatTime(listeningMinutes, listeningSeconds))
    }
    
  }
  const getPreviousAssessments = () => {
    if (assessments && assessments.length > 0) {
      return (
        <>
          <SoftBox pt={2} mb={1}>
            <SoftTypography variant="body1" fontWeight="fontWeight600">
              Candidate Assessment
            </SoftTypography>
          </SoftBox>

          <Card pt={3}>
            <SoftBox pt={1} pb={2} px={2}>
              <SoftBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                {assessments.map((assessment2) => {
                  const isSelected = assessment2.uid === assessment.uid;

                  return (
                    <SoftBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      borderRadius="lg"
                      p={1}
                      mb={0}
                    >
                      <SoftTypography variant="body2" color="text.secondary">
                        <StyledLink
                          isSelected={isSelected}
                          onClick={() => handleSetAssessment(assessment2)}
                        >
                          {getDateStr(assessment2)}
                        </StyledLink>
                      </SoftTypography>
                    </SoftBox>
                  );
                })}
              </SoftBox>
            </SoftBox>
          </Card>
        </>
      );
    } else return <></>;
  };

  const handleNext = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: steps[activeStep + 1]
      },
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSetAssessment = (assessment2) => {
    setTestTimes(assessment2)
    setAssessment(assessment2);
    setFeedback(assessment2.feedback);
    setAudioUrl(assessment2.audioUrl);
    setIsLoading(false);
    setVocabText(assessment2.vocabtext || []);
    if (assessment2.currentStep) {
      setTestStatus(assessment2.currentStep);
    }
    if (assessment2.listeningAnswer) {
      const groupedAnswers = assessment2.listeningAnswer.reduce((acc, curr) => {
        if (!acc[curr.audioFile]) {
          acc[curr.audioFile] = [];
        }
        acc[curr.audioFile].push(curr);
        return acc;
      }, {});
      const groupedAnswersArray = Object.entries(groupedAnswers).map(
        ([audioFile, answers]) => ({
          audioFile,
          answers,
        })
      );

      setListeningTestArray(groupedAnswersArray);
    }
  };
  const handleBack = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "component_loaded",
        component: steps[activeStep - 1]
      },
    });
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const emailCode = async (isPass) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("recipient_email", user.email);
    formData.append("code", user.uid);
    formData.append("recipient_name", user.firstName + " " + user.lastName);

    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + "/send_template",
      {
        method: "POST",
        body: formData,
        agent: new https.Agent({
          rejectUnauthorized: false,
          requestCert: false,
          agent: false,
        }),
      }
    );
    const result = await response.text();
    setIsLoading(false);
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 4000);
  };

  const handleFeedback = (isPass) => {
    setIsLoading(true);
    updateDoc(doc(collection(db, "assessments"), assessment.uid), {
      feedback: feedback ? feedback: '',
      isPass: isPass,
      resultStatus: isPass ? "Passed" : "Rejected",
    })
      .then(() => {
        setIsLoading(false);
        setFeedbackSubmitted(true);
        setFeedbackPass(isPass);

        setTimeout(() => {
          setFeedbackSubmitted(false);
        }, 5000);

        console.log("Updated feedback successfully");
      })
      .catch((error) => {
        console.error("Error feedback:", error);
      });

    updateDoc(doc(collection(db, "users"), assessment.userId), {
      isPass: isPass,
      resultStatus: isPass ? "Passed" : "Rejected",
    })
      .then(() => {
        console.log("Updated feedback successfully");
      })
      .catch((error) => {
        console.error("Error feedback:", error);
      });
  };

  const getListeningPage = (isPdf) => {
    if (assessment)
      return (
        <>
          <SoftBox pt={1} pb={2}>
            <SoftBox pt={3}>
              <SoftTypography variant="body1" fontWeight="medium">
                Listening
              </SoftTypography>
              <SoftTypography variant="h6" fontWeight="medium">
                Score: {assessment.listeningScore} %
              </SoftTypography>

              {listeningTime && (<SoftTypography variant="h6" fontWeight="medium">
                  Time: {listeningTime}
                </SoftTypography>)}
            </SoftBox>

            {listeningTestArray.map((audioObj, index) => (
              <>
                {!isPdf && (
                  <SoftBox pt={3} px={2}>
                    <AudioWrapper
                      style={{ marginTop: "12px", marginBottom: "12px" }}
                    >
                      <audio controls>
                        <source src={audioObj.audioFile} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </AudioWrapper>
                  </SoftBox>
                )}

                <SoftBox pt={2} px={1}>
                  {audioObj.answers.map((answer, index) => (
                    <VocabCard vocab={answer} key={index} />
                  ))}
                </SoftBox>
              </>
            ))}
          </SoftBox>
          <SoftBox pb={5} />
        </>
      );
  };
  const getQuestionsPage = (isPdf) => {
    if (assessment)
      return (
        <>
          <SoftBox pt={1} pb={2}>
            <SoftBox pt={3}>
              <SoftTypography variant="body1" fontWeight="medium">
                Vocabulary and Grammer
              </SoftTypography>
              <SoftTypography variant="h6" fontWeight="medium">
                Score: {assessment.vocabScore} %
              </SoftTypography>
              {grammerTime && (<SoftTypography variant="h6" fontWeight="medium">
                  Time: {grammerTime}
                </SoftTypography>)}
            </SoftBox>
            <SoftBox pt={2} px={1}>
              {vocabText.length > 0 ? (
                vocabText.map((vocab, index) => (
                  <VocabCard key={index} vocab={vocab} />
                ))
              ) : (
                <SoftTypography variant="h6" fontWeight="medium">
                  No Vocababulary Test answers found
                </SoftTypography>
              )}
            </SoftBox>
          </SoftBox>
          <SoftBox pb={5} />
        </>
      );
  };
  const getReviewRecordingPage = (isPdf) => {
    try {
      if (assessment) {
        return (
          <>
            <SoftBox pt={1} pb={2}>
              <SoftBox pt={3}>
                <SoftTypography variant="body1" fontWeight="medium">
                  Recordings
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="medium">
                  Score: {assessment.reading_similarity} %
                </SoftTypography>
                {recordingTime && (<SoftTypography variant="h6" fontWeight="medium">
                  Time: {recordingTime}
                </SoftTypography>)}
              </SoftBox>
              {audioUrl && !isPdf ? (
                <SoftBox pt={3} px={2}>
                  <AudioWrapper
                    style={{ marginTop: "12px", marginBottom: "12px" }}
                  >
                    <audio controls src={audioUrl}></audio>
                  </AudioWrapper>
                </SoftBox>
              ) : null}
              <Card
                variant="outlined"
                // style={{ marginLeft: "2px", marginRight: "2px" }}
              >
                <SoftBox pt={3} px={2}>
                  <UserTranscribe>
                    {getDiffs(assessment.passage, assessment.transcribe).map(
                      (diff, index) => {
                        const DiffComponent =
                          diff[0] === 1
                            ? DiffAdd
                            : diff[0] === -1
                            ? DiffDelete
                            : "span";
                        return (
                          <DiffWrapper key={index}>
                            <DiffComponent>{diff[1]}</DiffComponent>
                            <Tooltip>{diff[1]}</Tooltip>
                          </DiffWrapper>
                        );
                      }
                    )}
                  </UserTranscribe>
                  {/* <UserPassage>{user.passage}</UserPassage> */}
                </SoftBox>
              </Card>
            </SoftBox>
            <SoftBox pb={5} />
          </>
        );
      }
    } catch (err1) {
      console.log(err1);
    }
  };
  const getScreeningPage = (isPdf) => {
    return (
      <>
        {user && (
          <SoftBox pt={1} pb={2}>
            {!isPdf && (
                  <SoftButton
                    variant="text"
                    color="gt_primary"
                    onClick={(event) => handleExportClick()}
                  >
                    Export to PDF
                  </SoftButton>
                )}
            {getCandidateInfo()}
            {assessment && assessment.currentStep === "Finish" ? (
              <>
                {getCandidateScores()}
                
              </>
            ) : (
              <div>
                {!isPdf && (
                  <SoftButton
                    variant="text"
                    color="gt_primary"
                    onClick={(event) => emailCode()}
                  >
                    Email the Code
                  </SoftButton>
                )}
              </div>
            )}

            {getPreviousAssessments(assessments)}
          </SoftBox>
        )}
        <SoftBox pb={5} />
      </>
    );
  };
  const getFeedbackPage = (isPdf) => {
    if (assessment)
      return (
        <>
          {feedbackSubmitted && (
            <SoftBox
              mt={2}
              mb={2}
              textAlign="center"
              bgColor={feedbackPass ? "info" : "warning"}
            >
              <h6
                style={{
                  fontSize: ".8em",
                  color: "dark",
                  textAlign: "center",
                  fontWeight: 400,
                  transition: ".2s all",
                }}
              >
                {feedbackPass ? "Candidate selected" : "Candidate rejected"}
              </h6>
            </SoftBox>
          )}
          <SoftBox pt={3}>
            <SoftTypography variant="body2" fontWeight="medium">
              Feedback
            </SoftTypography>
          </SoftBox>
          <Card>
            {!isPdf ? (
              <SoftBox pt={1} pb={2}>
                <SoftBox p={2}>
                  <SoftInput
                    placeholder="Feedback..."
                    multiline
                    rows={12}
                    variant="outlined"
                    value={feedback}
                    onChange={(event) => setFeedback(event.target.value)}
                    // icon={{ component: "search", direction: "left" }}
                  />
                </SoftBox>
                <SoftBox pt={3}>
                  <SoftButton
                    variant="text"
                    color="gt_primary"
                    onClick={(event) => handleFeedback(false, event)}
                  >
                    Reject
                  </SoftButton>
                  <SoftButton
                    variant="text"
                    color="gt_primary"
                    onClick={(event) => handleFeedback(true, event)}
                  >
                    Pass
                  </SoftButton>
                </SoftBox>

                <SoftBox pb={5} />
              </SoftBox>
            ) : (
              <>{feedback} </>
            )}
          </Card>
        </>
      );
  };
  const printRef = React.useRef();
  const handleExportClick = async () => {
    const input = document.getElementById("my-pdf");
    const input1 = document.getElementById("my-pdf1");

    // set display property to "block"
    input.style.display = "block";

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
        compress: true,
      });
      const imgWidth = pdf.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let position = 0;

      // add image to first page
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);

      // calculate number of additional pages needed
      const numExtraPages = Math.ceil(
        (imgHeight - pdf.internal.pageSize.getHeight()) /
          pdf.internal.pageSize.getHeight()
      );

      // add extra pages if necessary
      for (let i = 1; i <= numExtraPages; i++) {
        pdf.addPage();
        position -= pdf.internal.pageSize.getHeight();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      }

      // Remove extra empty pages
      const pageCount = pdf.internal.getNumberOfPages();
      for (let i = pageCount - 1; i > 0; i--) {
        const pageObj = pdf.internal.pages[i];
        if (pageObj === null) {
          pdf.deletePage(i);
        } else {
          break;
        }
      }

      // set display property back to "none"
      input.style.display = "none";

      pdf.save(user.email + ".pdf");
    });
  };

  const renderStep2 = () => {
    return (
      // <Box sx={{ width: "100%", overflowX: "auto" }}>
      <div>
        <div>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              color: "gt_primary",
              maxWidth: "100%",
              display: "flex",
              my: 4,
              // "& .MuiStepLabel-label": { fontSize: "2rem" },
              "& .MuiStepIcon-root": {
                width: "0.8em",
                height: "0.8em",
                color: "yellow",
              },
              "& .MuiStepIcon-root.Mui-completed": {
                width: "0.8em",
                height: "0.8em",
                color: "yellow",
              },
              connector: {
                lineHeight: 1.6,
                "@media (min-width: 600px)": {
                  lineHeight: 1,
                },
              },
            }}
            // sx={{ "& .MuiStep-root": { paddingLeft: 0, paddingRight: 0 } }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label}>
                  {/* <StepLabel sx={{ fontSize: "12px", maxWidth: "100px" }}>{label}</StepLabel> */}
                  <StepLabel
                    sx={{
                      color: "gt_primary",
                      "& .MuiStepLabel-label": {
                        lineHeight: isMobile ? 1.4 : 1.6,
                        fontSize: isMobile ? 14 : 16,
                        height: "20px",
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <></>
          ) : (
            <>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
              <Container>{renderStep()}</Container>

              {testStatus &&
                assessment &&
                assessment.currentStep !== BEGIN_TEST && (
                  <Container
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      width: "auto",
                      // opacity: 0.8,
                      color: "white",
                      height: "3rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0 1rem",
                      boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.25)",
                      position: "fixed",
                      left: 0,
                      right: 0,
                      bottom: 0,
                      zIndex: 999,
                      // margin: "0 1rem 1rem",
                      // marginBottom: "1rem",
                    }}
                  >
                    {activeStep === 0 ? null : (
                      <SoftButton
                        variant="text"
                        color="gt_primary"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </SoftButton>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    {activeStep === steps.length - 1 ? null : (
                      <SoftButton
                        variant="text"
                        color="gt_primary"
                        onClick={handleNext}
                      >
                        Next
                      </SoftButton>
                    )}
                  </Container>
                )}
            </>
          )}
        </div>
      </div>
    );
  };

  function getDiffs(text1, text2) {
    try {
      const dmp = new diff_match_patch();
      const diffs = dmp.diff_main(text1, text2);
      dmp.diff_cleanupSemantic(diffs);
      return diffs;
    } catch (error) {
      // console.error("Error in getDiffs function:", error);
      // handle the error here, such as returning an empty array
      return [];
    }
  }

  const getDateStr = (assessement3) => {
    let dateString = "";
    const date = new Date(assessement3.createdAt.seconds * 1000);
    dateString = date.toLocaleDateString("en-US", {
      minute: "2-digit",
      hour: "2-digit",
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
    });
    return dateString;
  };

  const getCandidateInfo = (isPdf) => {
    return (
      <>
        <SoftBox pt={2} mb={1}>
          <SoftTypography variant="body1" fontWeight="fontWeight600">
            Candidate Information
          </SoftTypography>
        </SoftBox>
        <Card variant="outlined">
          <SoftBox pt={1} pb={1} px={2}>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
            >
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="button" textTransform="capitalize">
                  {user.firstName} {user.lastName}
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7" color="text">
                  Email:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {user.email}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7" color="text">
                  Phone:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {user.phoneNumber}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                borderRadius="lg"
                p={1}
                pb={2}
                mb={0}
              >
                <SoftTypography variant="h7" color="text">
                  Code:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {user.uid}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </>
    );
  };

  const getCandidateScores = () => {
    let showStatus = false;
    if (assessment.hasOwnProperty('isPass')) {
      showStatus = true;
    }

    return (
      <>
        <SoftBox pt={2} mb={1}>
          <SoftTypography variant="body1" fontWeight="fontWeight600">
            Candidate Score
          </SoftTypography>
        </SoftBox>
        <Card variant="outlined">
          <SoftBox pt={1} pb={2} px={2}>
            <SoftBox
              component="ul"
              display="flex"
              flexDirection="column"
              p={0}
              m={0}
            >
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7">
                  Region:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {assessment.region}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7">
                  Listening Score:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {assessment.listeningScore} %
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7">
                  Vocabulary Score:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {assessment.vocabScore} %
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7">
                  Reading Similarity:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {assessment.reading_similarity
                      ? `${assessment.reading_similarity}%`
                      : null}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              {showStatus && (
                <>
                  <SoftBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    // bgColor="grey-100"
                    borderRadius="lg"
                    p={1}
                    mb={0}
                  >
                    <SoftTypography variant="h7">
                      Status:&nbsp;&nbsp;&nbsp;
                      <SoftTypography variant="h7" fontWeight="medium">
                        {assessment.isPass ? `Pass` : "Rejected"}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox
                    component="li"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    // bgColor="grey-100"
                    borderRadius="lg"
                    p={1}
                    mb={0}
                  >
                    <SoftTypography variant="h7">
                      Feedback:&nbsp;&nbsp;&nbsp;
                      <SoftTypography variant="h7" fontWeight="medium">
                        {assessment.feedback}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                </>
              )}
               <SoftBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                // bgColor="grey-100"
                borderRadius="lg"
                p={1}
                mb={0}
              >
                <SoftTypography variant="h7">
                  Overall Time:&nbsp;&nbsp;&nbsp;
                  <SoftTypography variant="h7" fontWeight="medium">
                    {startTime}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      </>
    );
  };

  const renderStep = () => {
    if (isSinglePage) {
      return (
        <>
          {getScreeningPage()}
          {getReviewRecordingPage()}
          {getQuestionsPage()}
          {getListeningPage()}
          {getFeedbackPage()}
        </>
      );
    } else {
      switch (steps[activeStep]) {
        case SCREENING:
          return getScreeningPage();
        case REVIEW_RECORDING:
          return getReviewRecordingPage();
        case REVIEW_QUESTIONS:
          return getQuestionsPage();
        case REVIEW_LISTENING:
          return getListeningPage();

        case FEEDBACK:
          return getFeedbackPage();
        default:
          return null;
      }
    }
  };

  if (isLoading) {
    // return <p>Loading...</p>;
    return <MySpinner />;
  }

  return (
    <div>
      <Banner showBackButton={true} />
      {success && (
        <div style={{ textAlign: "center", margin: "10px" }}>Email Sent</div>
      )}
      {renderStep2()}

      <Container
        id="my-pdf"
        // ref={printRef}
        style={{ display: "none" }}
      >
        {getScreeningPage(true)}
        {getReviewRecordingPage(true)}
        {getQuestionsPage(true)}
        {getListeningPage(true)}
        {getFeedbackPage(true)}
      </Container>

      {/* <div id="my-pdf1" >
        <Container

        // style={{display:"none"}}
        >
          {getScreeningPage(true)}
          {getReviewRecordingPage(true)}
          {getQuestionsPage(true)}
          {getListeningPage(true)}
          {getFeedbackPage(true)}
        </Container>
      </div> */}
    </div>
  );
}
