import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppBar, Box, Card, IconButton, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { auth } from "../firebase";
import { navbar } from "./Navbars/DashboardNavbar/styles";
import SoftTypography from "./SoftTypography";
import Headroom from "react-headroom";
import logo from "../../public/logo/GlowSkillsLogo.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SoftBox from "./SoftBox";
import { navbarIconButton } from "./helper";


function Banner({ showBackButton, linkTxt, showLogoutButton }) {
  const theme = useTheme();
  const history = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        // Sign-out successful, redirect to login page
        localStorage.removeItem("prevPath");
        localStorage.removeItem("user");
        localStorage.removeItem("loggedIn");
        window.location.href = "/login";
      })
      .catch((error) => {
        // An error happened
        console.error(error);
      });
  };

  const handleGoBack = () => {
    history(-1);
  };

  const isMobile = theme.breakpoints.down("sm");
  const hasHistory = location.pathname !== "/";
  function handleClick() {
    window.location.reload();
  }
  const absolute = true;
  const transparentNavbar = false;
  const light = true;
  return (
    <AppBar
      position="static"
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Headroom
        style={{
          webkitTransition: "all .4s ease-in-out",
          mozTransition: "all .5s ease-in-out",
          oTransition: "all .5s ease-in-out",
          transition: "all .5s ease-in-out",
          zIndex: "1000",
          paddingRight: '0.5rem !important',
        }}
      >
        {/* <Headroom> */}
        <Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // px: 2,
              py: 0.5,
              bgcolor: "primary.default",
              color: "primary.contrastText",
            }}
          >
            {linkTxt ? (
              <Link
                to="/"
                onClick={handleClick}
                style={{ textDecoration: "none", color: "white" }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // px: 2,
                    // py: 1,
                    bgcolor: "primary.default",
                    color: "primary.contrastText",
                  }}
                >
                  <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      width: "35%",
                      marginRight: "16px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        height: "50px",
                        marginLeft: "10px",
                        marginRight: "8px",
                      }}
                    />
                  </SoftBox>
                  {/* <SoftBox
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "70%",
                      marginLeft: "1rem"
                    }}
                  >
                  <SoftTypography
                      variant={isMobile ? "h4" : "h3"}
                      color="gt_primary"
                      underline="none"
                    >
                      GlowSkills
                    </SoftTypography>
                  </SoftBox> */}
                </SoftBox>
              </Link>
            ) : (
              <SoftBox
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // px: 2,
                  // py: 1,
                  bgcolor: "primary.default",
                  color: "primary.contrastText",
                }}
              >
                <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "35%",
                    marginRight: "16px",
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      height: "50px",
                      marginLeft: "10px",
                      marginRight: "8px",
                    }}
                  />
                </SoftBox>
                {/* <SoftBox
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "70%",
                    marginLeft: "1rem"
                  }}
                >
                  <SoftTypography
                    variant={isMobile ? "h4" : "h3"}
                    // color="white"
                    color="gt_primary"

                    underline="none"
                  >
                    GlowSkills
                  </SoftTypography>
                </SoftBox> */}
              </SoftBox>
            )}
            {showLogoutButton && (
              <IconButton
                size="small"
                // color="inherit"
                color="light"
                sx={navbarIconButton}
                aria-controls="logout"
                aria-haspopup="true"
                variant="contained"
                onClick={handleLogout}
                style={ {marginRight:'16px' }}
              >
                <SoftTypography
                  variant="button"
                  fontWeight="bold"
                  color="gt_primary"
                >
                  Logout
                </SoftTypography>
              </IconButton>
            )}

            {isMobile && hasHistory && showBackButton && (
              <IconButton
                size="small"
                // color="inherit"
                color="light"
                sx={navbarIconButton}
                aria-controls="logout"
                aria-haspopup="true"
                variant="contained"
                onClick={handleGoBack}
                style={ {marginRight:'16px' }}
              >
                    <ArrowBackIosIcon
                    fontSize="large"
                    style={{ fontWeight: "bold", color: "#777777"  }}
                    />
              </IconButton>
            )}
          </Box>
        </Card>
      </Headroom>
    </AppBar>
  );
}

export default Banner;
